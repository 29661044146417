<template>
  <div class="LoginWrapper">
    <b-container fluid>
      <div class="LoginInner">
        <div>
          <b-modal
            v-model="show"
            id="modal-center"
            centered
            title=""
            hide-footer
            size="sm"
          >
            <template #modal-header>
              <h5 class="mx-auto text-primary">{{ $t("LOGIN SUCCESSFUL") }}</h5>
            </template>
            <div class="mx-auto text-center mb-2 mt-2">
              <i class="fa fa-check-circle fa-5x text-primary"></i>
            </div>
          </b-modal>
        </div>
        <b-row align-h="center">
          <b-col cols="12" sm="5">
            <div class="FormImg d-flex">
              <div class="my-4">
                <h3>{{ $t("Register") }}</h3>
                <span class="mb-4 d-block">
                  {{ $t("Don't have an account ? Register one!") }}</span
                >
                <b-button
                  variant="primary "
                  pill
                  class="rounded"
                  @click="register"
                >
                  {{ $t("Register an Account") }}</b-button
                >
              </div>
            </div>
          </b-col>
          <b-col cols="12" sm="7">
            <b-row>
              <b-col sm="12" cols="12" class="my-5">
                <b-form class="mx-2">
                  <b-form-group id="emailInput" label-for="firstNameI">
                    <b-form-input
                      id="emailInput"
                      type="text"
                      placeholder="الإيميل"
                      class="rounded-form"
                      v-model="$v.form.userMail.$model"
                      :state="validateState('userMail')"
                    ></b-form-input>
                    <b-form-invalid-feedback class="text-right mx-2">{{
                      $t("reqemail")
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group id="emailInput" label-for="passwordI">
                    <b-form-input
                      id="passwordI"
                      type="password"
                      placeholder="كلمة السر"
                      class="rounded-form"
                      v-model="$v.form.password.$model"
                      :state="validateState('password')"
                    ></b-form-input>
                    <b-form-invalid-feedback class="text-right mx-2"
                      >{{ $t("reqpass") }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group>
                    <b-button pill variant="primary" block @click="Login()"
                      >تسجيل الدخول</b-button
                    >
                  </b-form-group>
                </b-form>
                <div>
                  <p class="text-center m-4 mb-5">{{ $t("LoginUsing") }}</p>
                </div>
                <div class="mx-2">
                 <!-- <b-button
                    pill
                    block
                    style="background-color: #4267b2; border-color: #4267b2"
                    @click="LoginWithFacebook()"
                    ><div
                      class="
                        justify-content-center justify-content-between
                        align-items-center
                        mx-5
                      "
                    >
                      <i
                        class="
                          fab
                          fa-facebook-f
                          p-1
                          px-2
                          rounded-circle
                          bg-white
                          mx-2
                        "
                        style="color: #4267b2"
                      ></i
                      >{{ $t("LoginFace") }}
                    </div></b-button
                  >   -->
                  <b-button
                    pill
                    variant="primary"
                    block
                    style="background-color: #dd4b39; border-color: #dd4b39"
                    @click="LoginWithGoogle()"
                    ><div
                      class="
                        justify-content-center justify-content-between
                        align-items-center
                        mx-5
                      "
                    >
                      <i
                        class="
                          fab
                          fa-google
                          mx-1
                          p-1
                          rounded-circle
                          bg-white
                          mx-2
                        "
                        style="color: #dd4b39"
                      ></i
                      >{{ $t("LoginGoogle") }}
                    </div></b-button
                  >
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-link
                :to="{ name: 'Home' }"
                variant="outline-primary"
                class="mx-auto mb-4"
                >{{ $t("Main") }}</b-link
              >
            </b-row>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required, minLength, email } from "vuelidate/lib/validators";

export default {
  validations: {
    form: {
      userMail: {
        required,
        email,
      },

      password: {
        required,
        minLength: minLength(5),
      },
    },
  },
  mounted() {
       this.$gtag.event('SignIn Page', { method: 'Google' })
    
    window.addEventListener("message", this.onMessage);

  },
  beforeDestroy() {
    window.removeEventListener("message", this.onMessage);
  },
  computed: {
    ...mapGetters(["config"]),
  },
 
  methods: {
    ...mapActions(["LoginWithMail", "LoginWithSocialite"]),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    Login: function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.dispatch("LoginWithMail", this.form).then((response) => {
          if (response) {
            this.show=true
            setTimeout(() => {
              this.$router.push({ name: "Home" });
            }, 2000);
          }
        });

        // this.LoginWithMail(this.form);
      }
    },
    LoginWithGoogle: function () {
      const newWindow = openWindow("", "message");
      newWindow.location.href = this.config.google;
    },
    LoginWithFacebook: function () {
      const newWindow = openWindow("", "message");
      newWindow.location.href = this.config.facebook;
    },
    onMessage(e) {
      if (e.data.token && e.data.user) {
        this.LoginWithSocialite(e.data);
        this.show = true;
        setTimeout(() => {
          this.$router.push({ name: "Home" });
        }, 2000);
      }
    },
    register() {
      this.$router.push({ name: "Register" });
    },
  },

  data() {
    return {
      show: false,
      form: {
        userMail: "",
        password: "",
      },
    };
  },
    metaInfo: {
    title: "LogIn",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:"قم باستخدام بريدك الإلكتروني لعمل تسجيل دخول إلى الموقع ."      },
    ],
  },
};
function openWindow(url, title, options = {}) {
  if (typeof url === "object") {
    options = url;
    url = "";
  }

  options = { url, title, width: 600, height: 720, ...options };

  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screen.top;
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    window.screen.width;
  const height =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    window.screen.height;

  options.left = width / 2 - options.width / 2 + dualScreenLeft;
  options.top = height / 2 - options.height / 2 + dualScreenTop;

  const optionsStr = Object.keys(options)
    .reduce((acc, key) => {
      acc.push(`${key}=${options[key]}`);
      return acc;
    }, [])
    .join(",");

  const newWindow = window.open(url, title, optionsStr);

  if (window.focus) {
    newWindow.focus();
  }

  return newWindow;
}
</script>

<style scoped>
.FormImg {
  height: 100%;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;

  color: white;
  overflow: hidden;
  background: #c0484800; /* fallback for old browsers */
  background: linear-gradient(#fd690693, rgba(148, 72, 192, 0.281)),
    url("../assets/form.jpg"); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(#441c02fa, rgba(148, 72, 192, 0.281)),
    url("../assets/form.jpg"); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-size: cover;
  background-repeat: no-repeat;
}

.LoginInner {
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 30px 1px #00000052;
}

.LoginWrapper {
  background: #f4f4f4;
  padding: 10%;
}

@media only screen and (min-width: 320px) and (max-width: 625px) {
  .LoginWrapper {
    padding: 16px 0;
  }
}
</style>